<template>
  <div class="table_wrapper">
    <v-card flat tile>
      <v-toolbar class="rounded">
        <v-btn icon :to="'/currencies'">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Currency</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn color="primary" v-on:click="saveAllData" :loading="loading" dark>
          <v-icon>mdi-check</v-icon>
          Save
        </v-btn>
      </v-toolbar>

      <v-layout class="p-5" wrap row>
        <v-col cols="4">
          <v-text-field
            label="Name"
            v-model="data.name"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Code"
            v-model="data.code"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Symbol"
            v-model="data.symbol"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Usd Rate"
            v-model="data.usd_rate"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-switch v-model="data.is_enabled" label="Is Active"></v-switch>
        </v-col>
      </v-layout>
      <h5 class="pl-5">Translations</h5>
      <v-layout class="pt-0 p-5" wrap row v-if="pageLoaded">
        <v-col cols="4" v-for="(item, index) in langs" :key="index">
          <v-text-field
            :label="item.name"
            v-model="data.translations[item.id]"
            :loading="loading"
            required
          ></v-text-field>
        </v-col>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "CurrenciesEdit",
  data: () => ({
    loading: true,
    menu: false,
    token: localStorage.getItem("authToken"),
    langs: [],
    pageLoaded: false,
    data: {
      code: "",
      id: "",
      is_enabled: "",
      name: "",
      symbol: "",
      translations: {},
      usd_rate: "",
      id: null,
    },
  }),
  methods: {
    saveAllData: function() {
      this.loading = true;
      //this.data.id = Number(this.$route.params.id);

      if (this.data.id) {
        axios
          .put(
            process.env.VUE_APP_API_URL +
              "currency/" +
              this.data.id +
              "?api_token=" +
              this.token,
            this.data
          )
          .then((res) => {
            this.loading = false;
            this.$router.push({
              name: "Currencies",
            });
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .post(
            process.env.VUE_APP_API_URL + "currency?api_token=" + this.token,
            this.data
          )
          .then((res) => {
            this.loading = false;
            this.$router.push({
              name: "Currencies",
            });
          })
          .catch((err) => console.log(err));
      }
    },
    getData: function(id) {
      let url =
        process.env.VUE_APP_API_URL +
        "currency/" +
        id +
        "&api_token=" +
        this.token;
      axios
        .get(url)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((err) => console.log(err));
      this.getLang();
    },
    getLang: function() {
      let url =
        process.env.VUE_APP_API_URL +
        "language/filter?page=1&per_page=1000&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.langs = res.data.data.data.reverse();

          let mlangs = {};
          this.langs.forEach((item) => {
            mlangs[item.id] = "";
          });
          this.data.translations = mlangs;
          this.pageLoaded = true;
        })
        .catch((err) => {
          console.log(err);
          this.pageLoaded = true;
        });
    },
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
